import React, { ReactNode, FunctionComponent } from 'react';
import { useMediaQuery } from 'react-responsive';
import { motion, useReducedMotion } from 'framer-motion';
import { useAppSelector } from 'hooks/redux';

interface Props {
  children?: ReactNode;
}

export const ChatAnimation: FunctionComponent<Props> = (props) => {
  const isChatFixedWidth = useMediaQuery({
    query: '(max-width: 640px)'
  });

  const isChatFixedHeight = useMediaQuery({
    query: '(max-height: 410px)'
  });

  const shouldReduceMotion = useReducedMotion();
  const isCrowdVisible = useAppSelector((state) => state.crowd.isCrowdVisible);

  let variant = {};

  if (isChatFixedWidth || isChatFixedHeight) {
    variant = {
      visible: { y: 0, opacity: 1, scale: 1 },
      hidden: { y: '100%', opacity: 1, scale: 1 }
    };
  } else {
    variant = {
      visible: {
        opacity: 1,
        scale: 1,
        y: 0
      },
      hidden: {
        opacity: shouldReduceMotion ? 1 : 0,
        scale: shouldReduceMotion ? 1 : 0,
        y: 0
      }
    };
  }

  return (
    <motion.div
      className={`tw-flex-1 tw-max-w-full sm:tw-max-w-[480px] tw-relative ${
        isCrowdVisible
          ? 'lg:tw-max-w-[700px] xl:tw-max-w-[800px]'
          : 'lg:tw-max-w-[700px]'
      } tw-w-full`}
      initial={'hidden'}
      animate={'visible'}
      exit={'hidden'}
      transition={{ duration: 0 }}
      style={{ originY: 1, originX: 0.8 }}
      variants={variant}
    >
      {props.children}
    </motion.div>
  );
};
