import { IFrame } from 'sanitize-html';

export const giphyUrls =
  /(https?:\/\/(.+?\.)?giphy\.com(\/[A-Za-z0-9\-._~:#\]@!$&',;]*)?)/;

export const tenorUrls =
  /(https?:\/\/(.+?\.)?tenor\.com(\/[A-Za-z0-9\-._~:#\]@!$&',;]*)?)/;

export const santizeConfig = () => {
  const config = {
    allowedTags: ['a', 'img', 'br'],
    allowedAttributes: {
      a: ['href', 'target', 'title'],
      img: ['src', 'alt'],
      allowedSchemes: ['https']
    },
    exclusiveFilter: (frame: IFrame) => {
      return (
        frame.tag === 'img' &&
        !(
          giphyUrls.test(frame.attribs.src) || tenorUrls.test(frame.attribs.src)
        )
      );
    }
  };
  return config;
};
