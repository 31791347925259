import React, {
  FunctionComponent,
  useContext,
  useState,
  useRef,
  memo
} from 'react';
import styles from '../EmojiPicker/styles.module.scss';
import { Icon } from 'components/Icons';
import { useOnClickOutside } from 'usehooks-ts';
import { ThemeContext } from 'features/theme/theme';
import { useAppSelector } from 'hooks/redux';
import GifPicker, { TenorImage, Theme } from 'gif-picker-react';
import { useMediaQuery } from 'react-responsive';

interface GiphyPickerProps {
  onGifClick: (gif: TenorImage) => void;
}

export const GiphyPicker: FunctionComponent<GiphyPickerProps> = (props) => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [pickerOpen, togglePicker] = useState<boolean>(false);
  const { darkMode } = useContext(ThemeContext);
  const gifsInChatEnabled = useAppSelector(
    (state) => state.channel.channelDetails?.gifsInChatEnabled
  );
  const toggleHeight = useMediaQuery({ query: '(max-width: 739px)' });

  const handleEmojiClickOutside = () => {
    togglePicker(false);
  };

  useOnClickOutside(pickerRef, handleEmojiClickOutside);

  const onGifClick = (gif: TenorImage) => {
    props.onGifClick(gif);
    togglePicker(false);
  };

  if (!gifsInChatEnabled) {
    return null;
  }

  return (
    <div ref={pickerRef} className="tw-relative">
      <button
        onClick={() => togglePicker(!pickerOpen)}
        className={styles['emoji-picker__button']}
        type="button"
      >
        <Icon icon={'gif'} hidden={true} label={'Choose gif'} />
      </button>
      {pickerOpen && (
        <div className="tw-absolute tw-bottom-full tw-right-0 tw-shadow-xl">
          <GifPicker
            tenorApiKey={`${process.env.REACT_APP_TENOR_API_KEY}`}
            onGifClick={onGifClick}
            theme={darkMode ? Theme.DARK : Theme.LIGHT}
            height={toggleHeight ? 280 : 320}
            width={280}
          />
        </div>
      )}
    </div>
  );
};

export default memo(GiphyPicker);
